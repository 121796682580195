.swiper-container {
    width: 1920px;
    position: absolute;
    margin: 0 auto;
    height: 375px;
    .swiper-button-prev {
        left: 420px;
    }
    .swiper-button-next {
        right: 420px;
    }
}
.swiper-button-next,
.swiper-button-prev {
    width: 45px !important;
    height: 85px !important;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    background: url('../img/icon/prev.png') no-repeat center !important;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    background: url('../img/icon/next.png') no-repeat center !important;
}
.data {
    width: 230px;
    height: 84px;
    margin: 30px auto;
    position: relative;
    .data-img {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 76px;
        height: 76px;
        margin: 4px 0;
        z-index: 10;
    }
    .data-box {
        position: absolute;
        right: 0;
        top: 0;
        width: 170px;
        height: 84px;
        background-color: #fff;
        margin-left: -20px;
        text-align: center;
        h4 {
            font-size: 38px;
            margin: 0;
            line-height: 50px;
        }
        p {
            margin: 0;
        }
    }
}
/*首页盒模型*/
.content {
    margin-bottom: 35px;
    .content-title {
        width: 100%;
        background-color: #fff;
        padding: 9px 23px;
        margin-bottom: 2px;
        h4 {
            margin: 0;
            line-height: 35px;
            font-size: 24px;
        }
        p {
            margin: 0;
        }
    }
    .content-box {
        width: 100%;
        background-color: #fff;
        padding: 13px 24px;
        .icons {
            width: 158px;
            height: 112px;
            float: left;
            cursor: pointer;
            .icon-img {
                width: 56px;
                height: 56px;
                margin: 15px auto 8px;
                &.nongye {
                    background: url('../img/icon/nongye.png') no-repeat center;
                }
                &.shangye {
                    background: url('../img/icon/shangye.png') no-repeat center;
                }
                &.qihou {
                    background: url('../img/icon/qihou.png') no-repeat center;
                }
                &.xiaofei {
                    background: url('../img/icon/xiaofei.png') no-repeat center;
                }
                &.shengtai {
                    background: url('../img/icon/shengtai.png') no-repeat center;
                }
                &.jiaoyu {
                    background: url('../img/icon/jiaoyu.png') no-repeat center;
                }
                &.nengyuan {
                    background: url('../img/icon/nengyuan.png') no-repeat center;
                }
                &.shenghuo {
                    background: url('../img/icon/shenghuo.png') no-repeat center;
                }
                &.jinrong {
                    background: url('../img/icon/jinrong.png') no-repeat center;
                }
                &.jiankang {
                    background: url('../img/icon/jiankang.png') no-repeat center;
                }
                &.zhengfu {
                    background: url('../img/icon/zhengfu.png') no-repeat center;
                }
                &.zhizao {
                    background: url('../img/icon/zhizao.png') no-repeat center;
                }
                &.jiaotong {
                    background: url('../img/icon/jiaotong.png') no-repeat center;
                }
                &.gonggonganquan {
                    background: url('../img/icon/gonggonganquan.png') no-repeat center;
                }
                &.kexueyanjiu {
                    background: url('../img/icon/kexueyanjiu.png') no-repeat center;
                }
                &.jigoutuanti {
                    background: url('../img/icon/jigoutuanti.png') no-repeat center;
                }
                &.jingjifazhan {
                    background: url('../img/icon/jingjifazhan.png') no-repeat center;
                }
                &.xingyongfuwu {
                    background: url('../img/icon/xingyongfuwu.png') no-repeat center;
                }
            }
            .icon-text {
                text-align: center;
                font-size: 15px;
                color: #d2284c;
            }
        }
    }
    .content-items {
        padding: 5px 31px;
        background-color: #fff;
        .content-item {
            width: 311px;
            height: 217px;
            float: left;
            border-left: 2px solid #f3f3f3;
            &:first-child {
                border-left: none;
            }
            .item-title {
                font-size: 18px;
                text-indent: 45px;
                line-height: 45px;
                .more {
                    color: #d2284c;
                    float: right;
                    font-size: 14px;
                    margin-right: 34px;
                }
                &.down {
                    background: url('../img/icon/down.png') no-repeat 20px center;
                }
                &.up {
                    background: url('../img/icon/up.png') no-repeat 20px center;
                }
                &.dynamic {
                    background: url('../img/icon/dynamic.png') no-repeat 20px center;
                }
            }
            li {
                font-size: 14px;
                line-height: 24px;
                margin-left: 40px;
            }
        }
    }
}
/*底部*/
.bottom {
    margin-top: 85px;
    width: 100%;
    height: 184px;
    background-color: #ef4065;
    .main-content {
        padding: 0 114px;
        .bottom-items {
            margin-top: -65px;
            float: left;
            width: 386px;
            img {
                width: 191px;
                height: 142px;
                display: block;
                margin: 0 auto;
            }
            h4 {
                text-align: center;
                margin: 0;
                color: #fff;
                font-size: 23px;
                line-height: 40px;
            }
            p {
                text-align: center;
                margin: 0;
                color: #fff;
                font-size: 14px;
                line-height: 25px;
            }
        }
    }
}
